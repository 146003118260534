<template>
<div class="head-container">
  <el-select
      v-model="type"
      @change="toQuery"
      clearable
      placeholder="积分类型"
      class="filter-item"
      style="width: 130px"
  >
    <el-option
        v-for="item in typeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    />
  </el-select>
  <el-select
      v-model="actionType"
      @change="toQuery"
      clearable
      placeholder="行为类型"
      class="filter-item"
      style="width: 130px"
  >
    <el-option
        v-for="item in actionTypeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    />
  </el-select>
  <el-select
      v-model="status"
      @change="toQuery"
      clearable
      placeholder="状态"
      class="filter-item"
      style="width: 130px"
  >
    <el-option
        v-for="item in statusOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    />
  </el-select>
  <el-input
      v-model="basicInfo"
      clearable
      placeholder="客户姓名/ID"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
  />
  <div class="filter-item">
    到期时间：
    <el-date-picker
        style="width: 150px;"
        v-model="startTime"
        type="date"
        placeholder="开始日期">
    </el-date-picker>
    至
    <el-date-picker
        style="width: 150px;"
        v-model="endTime"
        type="date"
        placeholder="结束日期">
    </el-date-picker>
  </div>
  <el-button
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-search"
      @click="toQuery"
  >搜索</el-button>
  <el-button
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-close"
      @click="toClear"
  >清空</el-button>
</div>
</template>

<script>

export default {
  name: 'eheader',
  data() {
    return {
      type: '',
      typeOptions:[
        { label: '交易积分', value: 1 },
        { label: '行为积分', value: 2 },
      ],
      actionType: '',
      actionTypeOptions: [
        { label: '首次入金', value: 1 },
        { label: '单次入金', value: 2 },
      ],
      status: '',
      statusOptions: [
        { label: '待审核', value: 1 },
        { label: '已拒绝', value: 2 },
        { label: '有效', value: 3 },
        { label: '失效', value: 4 },
      ],
      basicInfo: '',
      startTime: '',
      endTime: '',
    }
  },



  methods: {
    toQuery() {
      this.$emit('toQuery');
    },
    toClear(){
      this.type = '';
      this.actionType = '';
      this.status = '';
      this.basicInfo = '';
      this.startTime = '';
      this.endTime = '';
      this.$emit('toQuery');
    }
  }
}
</script>

<style scoped>

</style>
