<template>
  <div class="app-container summary-page trading-record">
    <eHeader
      ref="eheader"
      @toQuery="toQueryHandle"
    ></eHeader>
    <el-table
        v-loading="loading"
        :data="data"
        stripe
        size="small"
        style="width: 100%;"
        show-summary
        :summary-method="getSummaries"
    >
      <el-table-column
          prop="id"
          label="ID"
          width="50"
      />
      <el-table-column prop="orderId" label="客户信息">
        <template slot-scope="scope">
          <div>{{scope.row.custName}}</div>
          <div>{{scope.row.custId}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="login" label="积分类型">
        <template slot-scope="scope">
          <span>{{getIntegralType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="行为类型">
        <template slot-scope="scope">
          <span>{{getBehavior(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="交易/入金数量">
        <template slot-scope="scope">
          <div>{{getPrefix(scope.row)}}{{scope.row.baseValue}}{{getSuffix(scope.row)}}&nbsp;({{scope.row.login}})</div>
        </template>
      </el-table-column>
      <el-table-column prop="symbol" label="积分计算">
        <template slot-scope="scope">
          <div>{{getIntegralComputed(scope.row)}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="earnedPoint" label="积分数量"/>
      <el-table-column prop="createTime" label="奖励时间"/>
      <el-table-column prop="expiredTime" label="到期时间"/>
      <el-table-column prop="openPrice" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column
          width="150px"
          prop="closeTime"
          label="操作"
      >
        <template slot-scope="scope">
          <el-button
              v-if="btnShow(scope.row)"
              @click="passHandle(scope.row)"
              size="mini"
              type="success"
          >通过</el-button>
          <el-button
              v-if="btnShow(scope.row)"
              @click="rejectHandle(scope.row)"
              size="mini"
              type="danger"
          >拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { audit } from '@/api/intergratingManage/index'
import checkPermission from '@/utils/permission'
import initDataComm from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/intergratingManage/auditList/eheader'
export default {
  name:'auditList',
  components: {
    eHeader,
  },
  mixins: [initDataComm],
  data() {
    return {
      loading: false,
      statistics:{
        totalPoint: ''
      },
      rejectLoading: false,
      passLoading: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.customerIntergralJumpToAuditListOfId){
        this.$refs.eheader.basicInfo = this.customerIntergralJumpToAuditListOfId;
      }
      this.init();
    })
  },
  beforeDestroy(){
    this.$store.commit('setCustomerIntergralJumpToAuditListOfId','');
  },
  computed: {
    ...mapGetters(['customerIntergralJumpToAuditListOfId']),
  },
  watch:{
    customerIntergralJumpToAuditListOfId(val){
      if(val){
        this.$refs.eheader.basicInfo = val;
        this.page = 0;
        this.init();
      }
    }
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      let header = this.$refs.eheader;
      this.url = 'crm/rewardPointTrans';
      this.params = {
        page: this.page,
        size: this.size,
      }
      if(header.type){
        this.params.rewardType = header.type;
      }
      if(header.actionType){
        this.params.actionType = header.actionType;
      }
      if(header.status){
        this.params.status = header.status;
      }
      if(header.basicInfo){
        this.params.custInfo = header.basicInfo;
      }
      if(header.startTime){
        this.params.startTime = parseTime(header.startTime);
      }
      if(header.endTime){
        this.params.endTime = parseTime(header.endTime);
      }
      return true
    },
    getIntegralType(row){
      return row.rewardType == 1 ? '交易积分': '行为积分';
    },
    getBehavior(row){
      if(row.rewardType == 1){
        return '-'
      }else {
        return row.actionType == 1 ? '首次入金' : '单次入金';
      }
    },
    getPrefix(row){
      if(row.rewardType == 2){
        return '$'
      }
    },
    getSuffix(row){
      if(row.rewardType == 1){
        return '手'
      }
    },
    getIntegralComputed(row){
      return row.calcInfo;
    },
    getStatus(row){
      switch(row.status){
        case 1:
          return '待审核';
        case 2:
          return '已拒绝';
        case 3:
          return '有效';
        case 4:
          return '失效';
      }
    },
    passHandle(row){
      this.loading = true;
      audit({
        id: row.id,
        status: 3,
      }).then((res) => {
        this.$notify({
          title: '通过审核',
          type: 'success',
          duration: 2500,
        });
        this.loading = false;
        this.init();
      })
    },
    rejectHandle(row){
      this.loading = true;
      audit({
        id: row.id,
        status: 2,
      }).then((res) => {
        this.$notify({
          title: '拒绝成功',
          type: 'success',
          duration: 2500,
        });
        this.loading = false;
        this.init();
      })
    },
    toQueryHandle(){
      this.page = 0;
      this.init();
    },
    getSummaries(param) {

      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        // 需要改成column.props
        switch (index) {
          case 0:
            sums[index] = '合计';
            break;
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 7:
          case 8:
          case 9:
          case 10:
            sums[index] = '-';
            break;
          case 6:
            sums[index] = this.statistics.totalPoint;
            break;
        }
      });

      return sums;
    },
    btnShow(row){
      return checkPermission(['ADMIN','TCustRewardPointTrans_ALL','TCustRewardPointTrans_EDIT']) && row.status == 1;
    },
  }
}
</script>

<style lang="less">
  .trading-record {
    .el-table {
          .el-table__footer-wrapper {
              .has-gutter {
                  tr {
                      .is-leaf:nth-of-type(6) {
                          .cell {
                              white-space: pre-wrap !important;
                          }
                      }
                  }
              }
          }
      }
    .result {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
</style>
